import axios from "axios";
import { API_ENDPOINT } from "@/utils/misc";

export const create = (payload) => {
  return axios.post(`${API_ENDPOINT()}/template-hours`, payload);
};

export const update = (id, payload) => {
  return axios.post(
    `${API_ENDPOINT()}/template-hours/${id}?_method=PUT`,
    payload
  );
};

export const remove = (id) => {
  return axios.delete(`${API_ENDPOINT()}/template-hours/${id}`);
};

export const getTemplateJob = (jobId, horecaLocationId) => {
  return axios.get(`${API_ENDPOINT()}/template-hours/getTemplateJob`, {
    params: { jobId, horecaLocationId },
  });
};
