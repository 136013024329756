<template>
  <div>
    <t-alert variant="success" class="my-2" :timeout="5000" :show="saveSuccess">
      {{ $t("changes_saved") }}
    </t-alert>
    <div class="flex items-center w-full md:w-1/2 mt-3 mb-5">
      <t-button
        variant="primary"
        class="text-sm mr-2"
        @click="$router.push('/manager/templates')"
      >
        <i class="fas fa-list"></i>
        {{ $t("overview") }}
      </t-button>
      <t-button class="text-sm mr-2" :disabled="true">
        <i class="fas fa-plus"></i>
        {{ $t("template_add") }}
      </t-button>
    </div>
    <div class="flex justify-between">
      <div class="w-full md:w-1/2 mb-5">
        <div class="mb-4">
          <label class="font-semibold" :class="{ 'text-red-500': errors.name }">
            {{ $t("name") }}
          </label>
          <div class="flex items-center">
            <t-input class="height-fix w-full" v-model="template.name" />
            <t-button
              class="text-sm ml-1 py-3"
              @click="copyTemplate"
              :disabled="loading"
              title="Copy template"
            >
              <i class="fas fa-clone"></i>
            </t-button>
          </div>
        </div>
        <div class="mb-4">
          <div class="flex items-center">
            <label
              class="font-semibold mr-3"
              :class="{ 'text-red-500': errors.locations }"
            >
              Horeca Locations
            </label>
            <label class="flex items-center">
              <t-checkbox
                @change="selectAllLocationChange"
                v-model="selectAllLocations"
              />
              <span class="ml-2 text-sm">{{
                $t("phone_list_all_option")
              }}</span>
            </label>
          </div>
          <t-rich-select
            multiple
            :close-on-select="false"
            :options="locations"
            v-model="template.locations"
            value-attribute="id"
            text-attribute="description"
            placeholder="Select horeca locations"
          ></t-rich-select>
        </div>
        <div class="mb-4">
          <label
            class="font-semibold"
            :class="{ 'text-red-500': errors.scenario_id }"
          >
            Scenario
          </label>
          <t-select
            class="w-full"
            :options="scenarios"
            v-model="template.scenario_id"
            value-attribute="id"
            text-attribute="name"
            placeholder="Select scenario"
          ></t-select>
        </div>
      </div>
      <div>
        <t-button
          @click="updateTemplate"
          class="text-sm"
          variant="success"
          :disabled="loading"
        >
          <i class="fas fa-save"></i>
          {{ $t("save_changes") }}
        </t-button>
      </div>
    </div>
    <table
      class="
        text-sm
        w-full
        divide-y divide-gray-100
        shadow-sm
        border-gray-200 border
        mb-3
      "
    >
      <thead>
        <tr>
          <th
            v-for="(header, key) in headers"
            class="font-semibold text-left bg-gray-100 border-b px-3 py-2"
            :key="`${key}-header`"
          >
            <div class="flex justify-between">
              <div>{{ header }}</div>
              <div class="text-gray-500">
                {{ (key - 1) | shiftCount(dayShiftCount) }}
              </div>
            </div>
          </th>
        </tr>
      </thead>
      <tbody class="bg-white divide-y divide-gray-100">
        <template v-for="(times, index) in templateData">
          <tr v-for="(cellData, cdi) in times" :key="`${index}${cdi}-row`">
            <td
              v-for="(data, ddi) in cellData"
              :key="`${index}${ddi}-cell`"
              class="px-3 py-2 whitespace-no-wrap align-top border"
            >
              <template v-if="ddi > 1">
                <div
                  v-for="(row, jbi) in data"
                  :key="`${index}${jbi}-data`"
                  class="job-item"
                >
                  <div class="flex items-center">
                    <span class="font-bold mr-2">
                      {{ row.job ? row.job.name || "" : "" }}
                    </span>
                    <button
                      class="text-blue-700"
                      @click="deleteTemplateHour(row.id)"
                      :disabled="loading"
                    >
                      &#10006;
                    </button>
                  </div>
                  <div>({{ row.from }} - {{ row.until }})</div>
                </div>
              </template>
              <div v-else>
                {{ !cdi && !ddi ? data : cdi && !ddi ? "" : data }}
              </div>
            </td>
          </tr>
          <tr :key="`${index}-divider`">
            <td
              colspan="9"
              class="border border-solid border-gray-300 p-0"
            ></td>
          </tr>
        </template>
        <tr>
          <td colspan="2"></td>
          <td v-for="(nth, index) in 7" :key="index" class="px-1 py-2">
            <label>{{ $t("start") }}</label>
            <t-select
              v-model="forms.start[nth]"
              class="w-full"
              :options="timeSlots.start"
            ></t-select>
          </td>
        </tr>
        <tr>
          <td colspan="2"></td>
          <td v-for="(nth, index) in 7" :key="index" class="px-1 py-2">
            <label>{{ $t("ending") }}</label>
            <t-select
              v-model="forms.end[nth]"
              class="w-full"
              :options="timeSlots.end"
            ></t-select>
          </td>
        </tr>
        <tr>
          <td colspan="2"></td>
          <td v-for="(nth, index) in 7" :key="index" class="px-1 py-2">
            <label>{{ $t("job_type") }}</label>
            <t-select
              v-model="forms.job[nth]"
              class="w-full"
              :options="jobs"
              value-attribute="id"
              text-attribute="name"
            ></t-select>
          </td>
        </tr>
        <tr>
          <td colspan="2"></td>
          <td v-for="(nth, index) in 7" :key="index" class="px-1 py-2">
            <t-button
              @click="addTemplateHour(nth)"
              class="w-full"
              variant="orange"
              :disabled="loading"
            >
              <i class="fas fa-plus"></i>
              {{ $t("to_add") }}
            </t-button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { actions, getters } from "@/constants/state";
import { getTimeSlotOptions } from "@/utils/misc";
import { getTemplateJobsList } from "@/services/template-job-service";
import {
  create as createTemplateHour,
  remove,
} from "@/services/template-hour-service";
import { copy, update } from "@/services/template-service";
import { chain, get, map, pick, sortBy } from "lodash";
import moment from "@/utils/moment-utc";
import { mapActions, mapGetters } from "vuex";

export default {
  data: () => ({
    jobs: [],
    template: {
      id: null,
      name: null,
      scenario_id: null,
      locations: [],
    },
    forms: {
      start: {},
      end: {},
      job: {},
    },
    errors: {
      name: false,
      scenario_id: false,
      locations: false,
    },
    selectAllLocations: false,
    saveSuccess: false,
    loading: false,
  }),
  async mounted() {
    this.initialize();

    if (this.hasAllLocations) {
      this.selectAllLocations = true;
    }
  },
  methods: {
    ...mapActions({
      fetchTemplate: actions.TEMPLATES_GET_EDIT_ACTION,
      getPageData: actions.TEMPLATES_FETCH_PAGE_DATA_ACTION,
    }),
    async initialize() {
      this.template = {
        ...this.editTemplate,
        locations: this.editTemplate.locations.map((location) => location.ID),
      };

      try {
        const { data } = await getTemplateJobsList();
        this.jobs = data;
        this.forms.start = { ...this.formValue("07:00") };
        this.forms.end = { ...this.formValue("07:00") };
        this.forms.job = { ...this.formValue(get(this.jobs, "0.id")) };
        this.fetchPageData();
      } catch (error) {
        console.error("getTemplateJobsList", error);
      }
    },
    async fetchPageData() {
      try {
        this.loading = true;
        await this.getPageData({ templateId: this.template.id });
      } catch (error) {
        console.error(actions.TEMPLATES_FETCH_PAGE_DATA_ACTION, error);
      }

      this.loading = false;
    },
    async updateTemplate() {
      Object.keys(this.errors).forEach((attribute) => {
        const value = get(this.template, attribute);
        this.errors[attribute] = Array.isArray(value) ? !value.length : !value;
      });

      if (Object.values(this.errors).some((error) => error)) {
        return;
      }

      try {
        this.loading = true;
        this.saveSuccess = false;

        await update({
          ...pick(this.template, ["id", "name", "scenario_id"]),
          locations: this.template.locations,
        });

        this.saveSuccess = true;
      } catch (error) {
        console.error("updateTemplate", error);
      }

      this.loading = false;
    },
    async copyTemplate() {
      if (confirm(" Are you sure you want to make a copy of this template?")) {
        try {
          this.loading = true;

          const { data } = await copy({
            name: `${this.template.name} copy`,
            templateId: this.template.id,
          });
          await this.$store.dispatch(actions.TEMPLATES_GET_EDIT_ACTION, data);
          this.$router.push(`/manager/templates/${data}/edit`);
          this.initialize();
        } catch (error) {
          console.error("copyTemplate", error);
        }

        this.loading = false;
      }
    },
    async addTemplateHour(day) {
      try {
        this.loading = true;
        await createTemplateHour({
          template_id: this.template.id,
          template_job_id: this.forms.job[day],
          from: this.forms.start[day],
          until: this.forms.end[day],
          day,
        });
        await this.fetchPageData();
      } catch (error) {
        console.error("addTemplateHour", error);
      }

      this.loading = false;
    },
    async deleteTemplateHour(id) {
      try {
        this.loading = true;
        await remove(id);
        await this.fetchPageData();
      } catch (error) {
        console.error("deleteTemplateHour", error);
      }

      this.loading = false;
    },
    formValue(value) {
      return {
        1: value,
        2: value,
        3: value,
        4: value,
        5: value,
        6: value,
        7: value,
      };
    },
    selectAllLocationChange() {
      this.$set(
        this.template,
        "locations",
        this.selectAllLocations
          ? map(this.locations, (location) => location.id)
          : []
      );
    },
  },
  computed: {
    ...mapGetters({
      isAdminOrSuperUser: getters.AUTH_IS_ADMIN_OR_SUPERUSER_GETTER,
      editTemplate: getters.TEMPLATES_EDIT_GETTER,
      templatesPageData: getters.TEMPLATES_PAGE_DATA_GETTER,
      dayShiftCount: getters.TEMPLATES_DAY_SHIFT_COUNT_GETTER,
      locations: getters.HORECA_LOCATIONS_GETTER,
      scenarios: getters.SCENARIOS_OVERVIEW_GETTER,
    }),
    hasAllLocations() {
      return this.template.locations.length === this.locations.length;
    },
    templateData() {
      return chain(this.templatesPageData)
        .map((template) => {
          const collected = [];
          let iteration = 0;

          for (const time in template.times) {
            collected[iteration] = [
              get(template, "job.NAME"),
              moment(time, "HH:mm:ss").format("HH:mm"),
              ...map(template.times[time], (jobs) => jobs),
            ];
            iteration++;
          }

          return collected;
        })
        .map((items) =>
          sortBy(items, [
            function (o) {
              return o[1];
            },
          ])
        )
        .value();
    },
    headers() {
      return [
        "",
        "",
        this.$i18n.t("monday"),
        this.$i18n.t("tuesday"),
        this.$i18n.t("wednesday"),
        this.$i18n.t("thursday"),
        this.$i18n.t("friday"),
        this.$i18n.t("saturday"),
        this.$i18n.t("sunday"),
      ];
    },
    timeSlots() {
      return getTimeSlotOptions();
    },
  },
  filters: {
    shiftCount(day, collection) {
      return get(collection, day) ? `[${get(collection, day)}]` : "";
    },
  },
  watch: {
    "template.locations": function () {
      this.selectAllLocations = this.hasAllLocations;
    },
    loading: function (value) {
      this.$emit("setLoading", value);
    },
  },
};
</script>

<style lang="scss" scoped>
.actions {
  & > * {
    max-height: 37.2px;
    margin-right: 0.5rem;
  }
}

.job-item:not(:first-child) {
  margin-top: 0.5rem;
}
</style>
